<template>
  <div id="contract-wrap">
    <EditTitle
        :max-length="maxLength"
        :title="contract.title"
        :can-write="canWrite"
        v-model:mode="editorMode"
        :disable-tooltip="disableTooltip"
        :upload-fn="updateTitle"
    />
  </div>

</template>

<script>

import {colorLog} from "../util";
import * as request from '../api/api';
import {ElMessage} from "element-plus";
import {globalTagClick} from "../assets/js/tag/tag";
import EditTitle from "./EditTitle";
import {nextTick, ref, toRef, toRefs, watch} from "vue";
export default {
  name: "ContractTitle",
  components: {EditTitle},
  props:{
    contract:{
      required:true
    },
    mode:{
      required:true
    },
    uploadFn:{
      required:false
    },
    disableTooltip:{
      required:false,
    },
    maxLength:{
      required:false,
    },
    canWrite:{
      required:false,
      default: true
    }
  },
  setup(props,ctx){
    const propRefs = toRefs(props);
    const editorMode = ref(false);
    watch(toRef(props,'mode'),(val) => {
      editorMode.value = val;
    },{
      immediate:true
    })
    watch(editorMode,(val) => {
      ctx.emit('update:mode',val);
    })


    const updateTitle = async (newTitle) => {
      const backup = propRefs.contract.value.title;
      propRefs.contract.value.title = newTitle;
      const res = await request.updateContractTitle(props.contract.id,newTitle);
      if(res.status==200){
        await nextTick();
        globalTagClick('contract-title-edited');
        ElMessage.success('修改成功');

      }else{
        globalTagClick("contract-title-edit-failed");
        ElMessage.error('修改失败');
        propRefs.contract.value.title = backup;
      }
    }

    return {
      propRefs,
      editorMode,
      updateTitle,
    }
  }
}
</script>

<style scoped>
</style>
