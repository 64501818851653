<template>
  <div class="comments-invisible-tip-group" ref="groupSelf"
  :style="{height:groupHeight ? groupHeight + 'px' : 0,
    top:offsetTop + 'px'
  }"
  >
    <CommentsInvisibleTip
        class="comment-tip"
        :class="{show:showTopTip}"
        ref="topTip"
        placement="top"
        :position="tipPositions.top"
        @click="handleTipClicked(true)"

    ></CommentsInvisibleTip>
    <CommentsInvisibleTip
        class="comment-tip"
        :class="{show:showBottomTip}"
        ref="bottomTip"
        placement="bottom"
        :position="tipPositions.bottom"
        @click="handleTipClicked(false)"
    ></CommentsInvisibleTip>
  </div>
</template>

<script>
import {colorLog, debounce, DomEventListenerManager, getHashColor} from "../../util";
import CommentsInvisibleTip from "./CommentsInvisibleTip";

export default {
  name: "CommentsInvisibleTipGroup",
  components: {CommentsInvisibleTip},
  props:{
    tipPositions:{ //{top:{top:xx,left:xx},bottom:{top:xx,left:xx}}
      required:true
    },
    thresholds:{  //{top:xx,bottom:xx}
      required:true
    },
    position:{
      required:false,
      default:{
        top:0,
        bottom:0,
      }
    },
    pdfRef:{
      required:false
    },
    commentsInfoInPdf:{
      required:false,
    }
  },
  data(){
    return {
      domEventListenerManager:new DomEventListenerManager(),
      showTopTip:false,
      showBottomTip:false,
      scrollRef:null,
      scrollResizeObserver:null,
      currentPosition:{top:null,left:null,bottom:null,right:null},
      topTipPosition:{top:null,left:null,bottom:null,right:null},
      bottomTipPosition:{top:null,left:null,bottom:null,right:null},
      groupHeight:0,
      offsetTop:0,
      aboveInvisibleCommentsPdfInfo:[],
      underInvisibleCommentsPdfInfo:[],
      judgeCommentsExceedThreshold:debounce(()=>{
        let status = []; //1为在评论中间 2为在下方不可见 3为在上方不可见
        let topTipLapped = false;
        let bottomTipLapped = false;
        this.$parent.commentRefs.forEach((ref,index)=>{
          let node = ref.$el;
          let markId = ref.markId;
          let {underTopTip, aboveBottomTip} = this.getPositionInfo(node);

          if(underTopTip && aboveBottomTip){
            if(this.isLappedWithTip(this.topTipPosition,node)){
              topTipLapped = true;
            }
            if(this.isLappedWithTip(this.bottomTipPosition,node)){
              bottomTipLapped = true;
            }
            status.push(1);
          }else if(underTopTip && !aboveBottomTip){
            if(this.commentsInfoInPdf){
              let commentInfo = this.commentsInfoInPdf.find(info => info.markId == markId);
              this.underInvisibleCommentsPdfInfo.push({start:commentInfo.start,end:commentInfo.end,markId});
            }
            status.push(2);
          }else if(!underTopTip && aboveBottomTip){
            if(this.commentsInfoInPdf){
              let commentInfo = this.commentsInfoInPdf.find(info => info.markId == markId);
              this.aboveInvisibleCommentsPdfInfo.push({start:commentInfo.start,end:commentInfo.end,markId});
            }
            status.push(3);
          }
          // colorLog[getHashColor(index)](`ref ${index} underTopTip:${underTopTip} aboveBottomTip:${aboveBottomTip}`)
        })

        if(!bottomTipLapped && status.some(state => state === 2)){
          this.showBottomTip = true;
        }
        if(!topTipLapped && status.some(state => state === 3)){
          this.showTopTip = true;
        }

      },200)
    }
  },
  beforeUnmount() {
    this.domEventListenerManager.removeListener(this.scrollRef,'scroll',this.handleScroll);
    this.domEventListenerManager.removeListener(window,'resize',this.handleWindowResize);
  },
  mounted() {
    if(!this.$refs.groupSelf) return;
    this.updateScrollRef();
    this.updateOffsetTop();
    this.updateGroupHeight();
    this.$nextTick(()=>{
      this.updateCurrentPosition();
      this.updateTipsPosition();
      this.judgeCommentsExceedThreshold();
    })
  },
  methods:{
    handleScroll(e){
      this.showTopTip = this.showBottomTip = false;
      this.updateOffsetTop();

      this.judgeCommentsExceedThreshold();
    },
    handleWindowResize(){
      if(!this.$refs.groupSelf) return;
      this.updateOffsetTop();
      this.updateGroupHeight();
      this.$nextTick(()=>{
        this.updateCurrentPosition();
      })
    },
    handleTipClicked(isTop){
      let selectable = null;
      let baseTop = -Infinity;
      let baseBottom = Infinity;
      let usePdfAPI = false;
      if(isTop){
        this.$parent.commentRefs.forEach(child => {
          let node = child.$el;
          let {underTopTip, aboveBottomTip,position:{top}} = this.getPositionInfo(node);
          if(!underTopTip && aboveBottomTip){
            if(top > baseTop){
              baseTop = top;
              selectable = node;
            }
          }
        });
      }else{
        if(this.underInvisibleCommentsPdfInfo){
          this.underInvisibleCommentsPdfInfo = this.underInvisibleCommentsPdfInfo.sort((a,b) => a.start - b.start);
        }

        this.$parent.commentRefs.forEach(child => {
          let node = child.$el;
          if(usePdfAPI) return;

          let {underTopTip, aboveBottomTip,position:{bottom},isNotResolvable} = this.getPositionInfo(node);
          if(isNotResolvable && child.markId == this.underInvisibleCommentsPdfInfo[0].markId){
            usePdfAPI  = true;
          }else{
            if(underTopTip && !aboveBottomTip){
              if(bottom < baseBottom){
                baseBottom = bottom;
                selectable = node;
              }
            }
          }
        });

      }

      if(usePdfAPI && this.pdfRef){
        //特殊情况,这个情况都是跳到下方的评论
        let {start,end} = this.underInvisibleCommentsPdfInfo[0];
        this.pdfRef.showRelationInPdf(start, end);
      }else{
        selectable.scrollIntoView({behavior:'smooth',block:'center',inline:'nearest'});
      }
    },
    getPositionInfo(node){
      let {top,bottom} = node.getBoundingClientRect();
      // colorLog[getHashColor('12')](`ref top:${top} - ${this.currentPosition.top} bottom:${bottom} - ${this.currentPosition.bottom}`)
      let underTopTip;
      let aboveBottomTip;
      let isNotResolvable = false
      if(top != bottom){
        underTopTip = (bottom - this.currentPosition.top) > this.thresholds.top;
        aboveBottomTip = (this.currentPosition.bottom - top)  > this.thresholds.bottom;
      }else{
        /**
         *  评论组件的top和bottom一样说明它还未和文档进行关联,pdf就存在这个问题,因为pdf组件懒加载,所以下方的某页并未加载出来,
         *  它这一页的评论页无法找到dom元素,就无法对齐位置.不过评论组件一定在下方,就认定在下方就好了
         */
        underTopTip = true;
        aboveBottomTip = false;
        isNotResolvable = true;
      }

      return {underTopTip,aboveBottomTip,position:{top,bottom},isNotResolvable};
    },
    updateScrollRef(){
      this.domEventListenerManager.removeListener(this.scrollRef,'scroll',this.handleScroll);
      this.domEventListenerManager.removeListener(window,'resize',this.handleWindowResize);

      let node = this.$refs.groupSelf;
      let nodeOverflowY = getComputedStyle(node).overflowY;
      while(nodeOverflowY != 'scroll' && nodeOverflowY != 'auto' && nodeOverflowY != 'overlay'){
        node = node.parentNode;
        nodeOverflowY = getComputedStyle(node).overflowY;
      }
      this.scrollRef = node;

      this.domEventListenerManager.registerListener(window,'resize',this.handleWindowResize);
      this.domEventListenerManager.registerListener(this.scrollRef,'scroll',this.handleScroll);
    },
    updateGroupHeight(){
      this.groupHeight = this.scrollRef.clientHeight - this.position.top - this.position.bottom;
    },
    updateCurrentPosition(){
      let {top,bottom,left,right} = this.$refs.groupSelf.getBoundingClientRect();
      this.currentPosition = {top,bottom,left,right};
    },
    updateOffsetTop(){
      if(!this.scrollRef){
        this.updateScrollRef();
      }
      let exitTop = this.$parent.$el.getBoundingClientRect().top - this.scrollRef.getBoundingClientRect().top;
      this.offsetTop = this.position.top - exitTop ;
    },
    updateTipsPosition(){
      let topTip = this.$refs.topTip.$el;
      let bottomTip = this.$refs.bottomTip.$el;
      //局部上下文区块 方便赋值
      {
        let {top,bottom,left,right} = topTip.getBoundingClientRect();
        this.topTipPosition = {top,bottom,left,right};
      }
      //局部上下文区块
      {
        let {top,bottom,left,right} = bottomTip.getBoundingClientRect();
        this.bottomTipPosition = {top,bottom,left,right};
      }
    },
    isLappedWithTip(tipPs,node){
      let {top,left,bottom,right} = node.getBoundingClientRect();

      return (tipPs.top <= top && tipPs.bottom >= top) ||
          (tipPs.top <= bottom && tipPs.bottom >= bottom) ||
          (top <= tipPs.top && tipPs.top <= bottom) ||
          (top <= tipPs.bottom && tipPs.bottom <= bottom)
    }
  },
}
</script>

<style scoped>
.comments-invisible-tip-group{
  position: absolute;
  width: 100%;
}

.comment-tip{
  pointer-events: none;
  visibility: hidden;
}
.comment-tip.show{
  pointer-events: initial;
  visibility: visible;
}
</style>
