<template>
  <div id="commentDialog"
       ref="commentDialog"
       @mouseenter="handleMouseEnter"
       @mouseleave="handleMouseLeave"
       :class="{commentDialogMini:dialogMini,'commentDialog-focus':isFocus,'commentDialog-hover':isHover}"
       @click="handleCommentDialogClick">

    <div id="dialog-header">
<!--      <div v-if="!dialogMini" class="header-btn" id="header-btn-mini" @click="handleMinBtnClick($event)">—</div>-->
      <div v-if="!readonly&&!dialogMini && comments.length" class="header-btn" id="header-btn-solve" @click="handleSolveBtnClick">✓</div>
    </div>
    <div id="dialog-comment" ref="dialogComment" class="">
      <template v-if="comments.length > 0">
      <div v-for="(comment,index) in (dialogMini ? comments.slice(0,1) : comments)" :key="comment.id" class="comment-message" :class="{'comment-message-other':index > 0}">
        <div class="message-left">
          <div class="message-left-avatar">
<!--            <img class="m-l-a-img" :src="comment.img">-->
<!--            <el-avatar :src="comment.img"  @error="()=>true">-->
<!--&lt;!&ndash;              <img src="static/img/loadImgError.png"/>&ndash;&gt;-->
<!--                <div  :style="{'background-color':getHashColor(comment.uid)}" class="stage-order " >-->
<!--                    <span>{{comment.name?getUserName(comment.name).substr(0,1):comment.uid.substr(0,1)}}</span>-->
<!--                </div>-->
<!--            </el-avatar>-->
            <user-icon :disable-avatar-border="true" :name="comment.name" :url="comment.img" :uid="comment.uid"></user-icon>
          </div>
        </div>
        <div class="message-right">
          <div class="message-right-header">
            <div class="m-r-h-username">{{comment.name}}</div>
            <div class="m-r-h-button m-r-h-delete" v-if="!readonly&&!dialogMini && index !== 0" @click="handleMessageDelete(comment)">删除</div>
          </div>
          <p class="message-right-content" :class="{messageRightContentMin:dialogMini}" v-html="comment.text">
          </p>
          <div class="message-right-footer" v-if="!dialogMini">
            <div class="m-r-f-time">{{_transformTimeInfo(comment.createTime,new Date().getTime())}}</div>
          </div>
        </div>
      </div>
      </template>
      <template v-else>
        <div id="nullCommentsTip">请添加批注</div>
      </template>
    </div>
    <div id="dialog-footer" v-show="!readonly&&!dialogMini">
      <div id="footer-messageInput" >
        <div class="messageInput-editArea"  ref="editCommentTextArea" @keydown="checkKeydown($event)" @input="commentDraftChange($event)" :placeholder="placeHolder" contenteditable="true">
        </div>
        <div class="messageInput-placeHolder" v-if="commentDraft.length == 0">
          {{placeHolder}}
        </div>


      </div>
        <div id="bottom-operations" style="padding:4px;display: flex;" :class="{flexBetween:showSwitchPermissionMode,flexEnd:!showSwitchPermissionMode}">
            <el-radio-group v-model="commentDraftPermissionMode" v-if="showSwitchPermissionMode" size="mini" style="margin-left:2px;" @change="viewPermissionChanged">
                <el-radio-button :label="PermissionMode.Public" title="本团队和邀请用户可见"><i class="material-icons" style="font-size: 1.38rem">public</i><span class="permissionMode-item">公开</span></el-radio-button>
                <el-radio-button :label="PermissionMode.Internal" title="仅本团队内部可见"><i class="material-icons" style="font-size: 1.38rem">domain</i><span class="permissionMode-item">内部</span></el-radio-button>
            </el-radio-group>

            <el-button @click="sendNewComment" size="mini" v-loading="isSendingCommentMsg">发送</el-button>
        </div>


    </div>

  </div>
</template>

<script>
import {transformTimeInfo, getBase64, hashCode, getHashColor, getUserName, isTrimEmpty, colorLog} from "../../util";
import {CommentType,RequestMessageType} from "../../assets/js/websocket/websocket-message-type";
    import {CommentPermissionMode} from "../common/constant";
    import userIcon from "../UserIcon";

export default {
  name: "CommentDialog",
  props:['comments','markId','position','permissionMode','isFocus','isHover','readonly','canSetPermissionMode'],
  components:{userIcon},
  data(){
    return {
        // currentUser:{
        //   uid:'0',
        //   name:'xxxxxxx1A',
        //   img:'/static/img/test_avatar.jpg',
        // },
      commentDraft:'',
      commentDraftPermissionMode:this.permissionMode,
      isSendingCommentMsg:false,
      dialogMini: true,
      // commentTextareaHeight:'auto'
      // inputReplySpanObserver:null,
      blurFun:null,
      isInConfirm:false,
      getHashColor:getHashColor,
      getUserName:getUserName,
      PermissionMode:CommentPermissionMode,
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
    }
  },
  watch:{
      'permissionMode':function (val) {
         this.commentDraftPermissionMode = val
      },
      'comments.length':function(){
        //用来在新添评论时自动把滚动条拉到最底
        let dialogComment = this.$refs.dialogComment;
        this.$nextTick(()=>{
          dialogComment.scrollTo(0,dialogComment.scrollHeight);
        })

      }
  },
  computed:{
      'placeHolder':function () {
          let res = 'Enter发送,Shift+Enter换行'
          let mode = this.commentDraftPermissionMode;
          if (this.comments.length > 0) {
              mode = this.comments[0].permissionMode
          }
          let suffix='';
          if (mode === CommentPermissionMode.Public) {
              suffix='(公开)'
          }else if (mode === CommentPermissionMode.Internal) {
              suffix='(内部)'
          }
          return res + suffix;
      },
      showSwitchPermissionMode:function () {
          return this.canSetPermissionMode && this.comments.length === 0;
      }
  },
  methods:{
    checkKeydown(e){
      if(e.keyCode == 13 || e.keyCode == 108){
        if(!e.shiftKey){
          this.sendNewComment();
          e.stopPropagation();
          e.preventDefault();
          return;
        }else{
          this.commentDraft += '<br/>';
        }
      }

    },
    commentDraftChange(e){
      this.commentDraft = this.$refs.editCommentTextArea.innerText;
    },
    viewPermissionChanged(val){
        this.$emit('update:permissionMode',val)
    },
    restoreDraftCommentDefault(){
        this.isSendingCommentMsg = false;
        this.commentDraft = '';
        this.$refs.editCommentTextArea.textContent = '';
    },
    handleMouseEnter(){
      if(this.isFocus){
        this.$emit('updateHoverAnnotationMarkId',null);
        return;
      }
      this.$emit('updateHoverAnnotationMarkId',this.markId);
    },
    handleMouseLeave(){
      this.$emit('updateHoverAnnotationMarkId',null);
    },
    _transformTimeInfo(start,end){
      return transformTimeInfo(start,end)
    },
    handleCommentDialogClick(){
      this.$emit('updateHoverAnnotationMarkId',null);
      this.$emit('updateFocusAnnotationMarkId',this.markId);
      this.createBlurListener();


      if(!this.dialogMini){
        return;
      }
      this.openDialog();


    },
    createBlurListener(){
      if(!this.blurFun){
        this.blurFun = (e)=>{
          if(this.isInConfirm){
            return;
          }
          let commentDialogDom = e.target.closest('#commentDialog');
          if(commentDialogDom !== this.$refs.commentDialog){
            // console.log('清除 focus',this.markId)
            this.minimizeDialog();
            this.destroySelf();
            this.$emit('updateFocusAnnotationMarkId',null);
            this.$nextTick(()=>{
              this.$emit('handlePositionChangeEvent');
            })
            document.removeEventListener('click',this.blurFun,{capture:true});
            this.blurFun = null;
          }
        }
        document.addEventListener('click',this.blurFun,{capture:true});
      }
    }
    ,
    minimizeDialog(){
      this.dialogMini = true;
    },
    openDialog(){
      this.dialogMini = false;
      this.$nextTick(()=>{
        this.focusTextArea();
        this.$emit('handlePositionChangeEvent',this.markId)
      })
    },
    destroySelf(){
      if(this.comments.length === 0){
        this.$emit('deleteAnnotation');
        // this.$emit('sendCommentOperation',RequestMessageType.COMMENT,{
        //   type:CommentType.SOLVE,
        //   comment:{
        //     markId:this.markId
        //   }
        // })
        return true;
      }
    },
    handleMinBtnClick(e){
      this.minimizeDialog();
       this.destroySelf();
      this.$emit('updateFocusAnnotationMarkId',null);
      document.removeEventListener('click',this.blurFun,{capture:true});
      this.blurFun = null;

      this.$nextTick(()=>{
        this.$emit('handlePositionChangeEvent')
      })
      e.stopPropagation();
    },
    sendNewComment(){
      let comment = this.commentDraft;
      if(this.isSendingCommentMsg){
        return;
      }
      if (!comment || isTrimEmpty(comment)) {
        this.$message.error('请输入有效评论内容')
        return;
      }
      this.isSendingCommentMsg = true;

      this.$emit('sendCommentOperation',RequestMessageType.COMMENT,{
        type:CommentType.CREATE,
        comment: {
          rootComment:this.comments.length===0,
          markId:this.markId,
          uuid:hashCode(comment) + '',
          text:comment,
          permissionMode:this.getDefaultPermissionMode()
        }
      });

      // let infoObj = Object.assign({},this.currentUser,{
      //   id:new Date() + '',
      //   time:new Date(),
      //   markId:this.markId,
      //   text:comment
      // })

      // setTimeout(()=>{
      //   this.addCommentMessage(infoObj);
      //
      // },1000)
    },
    getDefaultPermissionMode(){
        if (this.comments.length === 0) {
            return this.permissionMode
        }
        if(this.comments[0].permissionMode!==null){
            return this.comments[0].permissionMode;
        }
        return CommentPermissionMode.Internal;
    },
    addCommentMessage(comment){
      let originSrc = comment.img;
      this.$emit('addCommentMessage',this.markId,comment);
      this.restoreDraftCommentDefault();

      this.$nextTick(()=>{
        this.$refs.dialogComment.scrollTo(0,this.$refs.dialogComment.scrollHeight);
      })
    },
    focusTextArea(){
      const editCommentTextArea = this.$refs.editCommentTextArea;
      const textNode = editCommentTextArea.childNodes[0];
      editCommentTextArea.focus();
      if(textNode){
        let len = editCommentTextArea.textContent.length;
        const selection = window.getSelection();
        const range = document.createRange();
        range.setStart(textNode,len);
        range.setEnd(textNode,len);
        selection.removeAllRanges();
        selection.addRange(range);
      }
      // this.$refs.editCommentTextArea.setSelectionRange(len,len);
    },
    handleMessageDelete(comment){
      if (comment.uid !== this.userInfo.uid) {
          this.$message.error('暂无权限')
          return;
      }
      this.isInConfirm = true;
      this.$confirm('确定删除这项评论吗?','确认操作',{
        confirmButtonText:'确定',
        cancelButtonText:'取消',
        type:'warning'
      }).then(()=>{
        this.$emit('sendCommentOperation',RequestMessageType.COMMENT,{
          type:CommentType.DELETE,
          comment:comment
        })
        // this.$emit('deleteCommentMessage',this.markId,mid);
      }).catch((error)=>{
        console.error('取消删除',error)
      }).finally(()=>{
        this.isInConfirm = false;
      });
    },
    handleSolveBtnClick(){
      if (this.comments.length>0&&this.comments[0].uid !== this.userInfo.uid) {
          this.$message.error('暂无权限')
          return;
      }
      this.isInConfirm = true;
      this.$confirm('此举会解决该批注并删除该批注,您确定解决吗?','确认操作',{
        confirmButtonText:'确定',
        cancelButtonText:'取消',
        type:'warning'
      }).then(()=>{
        // this.$emit('resolveAnnotation');
        let comment = this.comments[0]
        this.$emit('sendCommentOperation',RequestMessageType.COMMENT,{
          type:CommentType.SOLVE,
          comment:comment
        })
        this.$emit('updateFocusAnnotationMarkId',null);
        document.removeEventListener('click',this.blurFun,{capture:true});
        this.blurFun = null;
      }).catch(()=>{
        console.log('取消解决')
      }).finally(()=>{
        this.isInConfirm = false;
      })

    },

  },
  mounted() {
    this.$parent.addCommentRef(this);
  },
  unmounted() {
    this.$parent.removeCommentRef(this);
  }
}
</script>

<style scoped>
    .flexBetween{
        justify-content: space-between;
    }
    .flexEnd{
        justify-content: flex-end;
    }
    .stage-order {
        width: 100%;
        /*width: 28px;*/
        /*height: 28px;*/
        text-align: center;
        /*line-height: 28px;*/
        /*border-radius: 20px;*/
        color: white;
        overflow: hidden;
        position: relative;



        flex-shrink: 0;
        margin-right: -3px;
        border: solid 1px rgba(255, 255, 255, 0.3);
    }
#commentDialog{
  font-size: 1em;
  box-shadow: 0 0 5px 2px #eee;
  background: #fff;
  transition: .5s all;
  width: 25em;
}
#commentDialog:hover{
  opacity: 1;
}
.commentDialog-hover{
  box-shadow: 0 0 5px 2px #ccc !important;

}
.commentDialog-focus{
  box-shadow: 0 0 5px 2px #ccc !important;
}
.commentDialogMini{
  opacity: .8;
  cursor: pointer;
  position: relative;
}
/*.commentDialogMini:hover::before{*/
/*  position: absolute;*/
/*  color: #cccc;*/
/*  content: '点击展开';*/
/*  font-size: 1.2em;*/
/*  text-align: center;*/
/*  background: 0;*/
/*  left: 50%;*/
/*  bottom: 0;*/
/*  transform: translateX(-50%);*/
/*}*/
#dialog-header,#dialog-footer{
  font-size: 1em;
  width: 100%;
  justify-content: flex-end;
  user-select: none;
  line-height: 2.5em;
}
#dialog-comment{
  max-height: 42em;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width:thin;
}
#dialog-comment::-webkit-scrollbar {
  display: block;
}
#dialog-comment::-webkit-scrollbar {
  width: .1em;
}
#dialog-comment::-webkit-scrollbar-thumb {
  background-color: #aaa;
  background-clip: padding-box;
  min-height: .1em;
}
#dialog-header{
  display: flex;
}
.header-btn{
  width: 2.5em;
  height: 2.5em;
  text-align: center;
}
#header-btn-mini:hover{
  background: #00cae0;
  color: #fff;
}
#header-btn-solve:hover{
  background: #44a586;
  color: #fff;
}
#header-btn-max:hover{
  background: #00cae0;
  color: #fff;
}

#header-btn-close:hover{
  background:#CC3333;
  color: #fff;
}
.comment-message{
  width: 100%;
  display: flex;
}
.comment-message-other{
  padding-left: 1em ;
}
.comment-message-other:hover .m-r-h-delete{
  visibility: visible;
}
.message-left{
  width: 4em;
  padding: 1em .5em;
  box-sizing: border-box;
}
.message-left-avatar{
  width: 3em;
  height: 3em;
}
.message-left-avatar  .el-avatar--small{
  width: 100% !important;
  height: 100% !important;
}
.m-l-a-img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding-top: .5em ;
}
.message-right{
  width: 20em;
  padding: .5em;
  box-sizing: border-box;

}
.message-right-header{
  width: 100%;
  /*height: 2.5em;*/
  display: flex;
  justify-content: space-between;
}
.message-right-content{
  width: 100%;
  word-wrap: break-word;
  text-align: left;
  user-select: text;
  white-space: pre-line;
  margin: 0;
  font-size: 1.2em;
  line-height: 1.2em;
}
.messageRightContentMin{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.message-right-footer{
  width: 19.1em;
  height: 2.5em;
  display: flex;
  justify-content: right;
}

.m-r-h-username{
  font-size: 1.3em;
  line-height: 1.8em;
  color: #6a737c;
  word-break: break-all;
  text-align: left;
}
.m-r-f-time{
  font-size: 1.2em;
  color: #6a737c;
}
.m-r-h-button{
  font-size: 1.3em;
  line-height: 1.8em;
  cursor: pointer;
}
.m-r-f-reply{
  color: #6a737c;
}
.m-r-h-delete{
  color:#CC3333;
  visibility: hidden;
}
#footer-messageInput{
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding: .5em;
  justify-content: space-between;
  position: relative;
}
.messageInput-editArea{
  /*width: 13.8em;*/
  width: 100%;
  text-align: left;
  /*background: #F7F7F7;*/
  padding: .3em;
  box-sizing: border-box;
  outline:none ;
  border: .1em solid #ccc;
  font-size: 1.2em;
  line-height: 1.5em;
  word-break: break-all;
}
.messageInput-editArea textarea{
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  resize: none;
  background: transparent;
  font-size: 1.2em;
  /*min-height: 3.2em;*/
}
.messageInput-placeHolder{
  position: absolute;
  font-size: 1.2em;
  color: #cbc0c0;
  top: 50%;
  left: .8em;
  transform: translate(0,-50%);
  pointer-events: none;
}
/*.messageIputNoneContent{*/
/*  background: #F7F7F7;*/
/*  position: relative;*/
/*}*/
/*.messageIputNoneContent::before{*/
/*  position: absolute;*/
/*  top: 50%;*/
/*  transform: translateY(-50%);*/
/*  left: .3em;*/
/*  content: '请输入评论';*/
/*  color: #aaa;*/
/*}*/

#messageInput-sendBtn{
  display: inline-block;
  padding: 0;
  margin: 0;
  width: 5em;
  height: 3.8em;
  line-height: 3.8em;
  user-select: none;
  box-sizing: border-box;
  color: #aaa;
  border: 1px solid #ccc;
  cursor: pointer;
  position: relative;
  text-align: center;
}

#otherCommentsTip{
  width: 20em;
  height: 2em;
  position: relative;
  padding-top: 1em;
}
#redPoint{
  position: absolute;
  left: 50%;
  transform: translateY(-50%);
  background: #CC3333;
  min-width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  line-height: 1.5em;
  color: #fff;
}
#redPoint span{
  font-size: 1em;
}
#otherCommentsTip svg{
  width: 2em;
  height: 2em;
}
#editArea-replyInfo{
  padding: .5em .5em 0 .5em;
  text-align: center;
  width: 20em;
  box-sizing: border-box;
}
#editArea-replyInfo p{
  margin: 0;
  background: #eeee;
  color: #aaa;
  cursor: pointer;
  word-break: break-all;
}
#messageInput-sendBtn-loadingImg{
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 1.5em;
  height: auto;
}
#nullCommentsTip{
  text-align: center;
  padding: 1em;
  font-size: 1.2em;
}
#bottom-operations >>> .el-radio-button__inner{
  padding: 7px 7px !important;
}
#bottom-operations >>> .material-icons,#bottom-operations >>> .permissionMode-item{
  vertical-align: middle;
}
#bottom-operations >>> .el-radio-button__original-radio:checked+.el-radio-button__inner{
    background-color: var(--lightBlue) !important;
    border-color: var(--lightBlue) !important;
  }
#bottom-operations >>>.el-button:hover{
  color:var(--lightBlue) !important;
  border-color:  var(--lightBlue) !important;
}
#bottom-operations >>> .el-radio-button:not(.is-active) .el-radio-button__inner:hover{
  color:var(--lightBlue) !important;
}
#bottom-operations >>> .el-radio-button.is-active .el-radio-button__inner:hover{
  background-color:var(--darkBlue) !important;
}
</style>
