<template>
    <el-dialog
            title="标准条款"
            :modal="true"
            :append-to-body	="false"
            v-model="clauseDialogVisible"

            width="900px">
        <div style="max-height: 400px;overflow-y: auto;text-align: left;">
            <div v-for=" (text,i) in clauseDialogTexts" :key="i">{{text}}</div>
        </div>

        <template #footer>
            <span  class="dialog-footer">
            <el-button type="primary" @click="copyClauseTexts()">复制</el-button>
            <el-button type="danger" @click="clauseDialogVisible = false">关闭</el-button>
        </span>
        </template>

    </el-dialog>
</template>

<script>

    import {copyStringToClipboard} from "@/util";

    export default {
        name: "StandardClauseDialog",
        data: function () {
            return {
                clauseDialogVisible:false,
                clauseDialogTexts:[]
            };
        },
        mounted() {
        },
        methods: {
            showClauseTextDialog:function(clauseTextString){
                let clauseTexts = clauseTextString.split("\n").filter(a=>a.trim()!=="");
                this.clauseDialogVisible = true;
                this.clauseDialogTexts = clauseTexts;
            },
            copyClauseTexts:function(){
                copyStringToClipboard(this.clauseDialogTexts.join("\n"));
                this.$message.success('复制成功');
            },

        },
    }
</script>

<style scoped lang="scss">

</style>
