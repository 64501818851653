<template>
  <div
      :data-alert-id="alertInfo.id"
      @click="alertClick(alertInfo)"
      :data-expand-status="alertInfo.calculateInfo.status"
      class="ue-alert-panel"
      ref="box"
      style="padding-left:18px;padding-right:18px;font-weight: 400;font-size: 14px;color: rgba(72, 72, 72, 1);line-height: 20px;"
  >
    <div class="animation-wrapper alertItemCollapseBorder collapseAlert"
         v-show="alertInfo.calculateInfo.status===AlertDisplayStatus.COLLAPSE">
      <!--收起状态-->
      <!--收起第一部分-->
      <div class="ue-alert-wrapper-left">
        <div class="noticeIntro" style="line-height: 45px;">
          <i class="meta-point meta-point-medium" :class="[getAlertBackgroundColorClass(alertInfo)]"></i>
          <template v-if="alertInfo.errorTypeDescription">
            <!--**有errorTypeDescription的按照新的排版-->
            <span class="alertItemCollapse sourceTextItemCollapse"
                  :title="alertInfo.errorTypeDescription">
                        {{ alertInfo.errorTypeDescription }}
                    </span>
          </template>
          <template v-else>
            <!--没有errorTypeDescription的按照老的排版-->
            <span class="alertItemCollapse sourceTextItemCollapse"
                  :title="alertInfo.sourceText">
                        {{ getCollapseLeftText(alertInfo) }}
                    </span>

            <i class="meta-point meta-point-small background-color-gray" style="margin: 0 5px;"></i>
            <span class="alertItemCollapseAlertType">{{ getCollapseMediumText(alertInfo) }}</span>

            <span v-if="isShowReplaceText(alertInfo)" class="alertItemCollapse replaceTextItemCollapse"
                  :title="alertInfo.sourceText">{{ getCollapseRightText(alertInfo) }}</span>
          </template>


        </div>
      </div>
      <!--收起第一部分结束-->
      <!--收起第二部分-->
      <div class="ue-alert-wrapper-right">
        <img src="https://uranus-static.oss-accelerate.aliyuncs.com/xiezuocat/open.png"/>
      </div>
      <!--收起第二部分结束-->
    </div>
    <div class="animation-wrapper" v-show="alertInfo.calculateInfo.status===AlertDisplayStatus.EXPAND">
      <!--展开状态-->
      <div class="ue-alert-wrapper-left-active foldAlert" v-if="alertInfo.errorType===ErrorType.FOLD_ALERT" >

            <!--如果是折叠类型的提示-->
          <div class="noticeIntro" style="margin-top: 8px; margin-bottom: 14px;">
            <i class="meta-point meta-point-medium" :class="[getAlertBackgroundColorClass(alertInfo)]"></i>
            <span class="alertItemExpandErrorType" style="margin-left: 14px">{{ alertInfo.errorTypeDescription  }}</span>
  <!--          <span class="feed-back-btn" v-if="teamInfo && teamInfo.debugEnabled" @click="feedBack(alertInfo)">反馈</span>-->
          </div>
          <div class="alertOneSection" v-if="alertInfo.briefMessage" style="color:rgba(72, 72, 72, 1);">{{alertInfo.briefMessage}}</div>

        <el-scrollbar max-height="300px">
          <el-collapse v-model="alertInfo.calculateInfo.activeSubAlertId" accordion @click.stop>
            <el-collapse-item  :name="subAlert.id" v-for="subAlert in alertInfo.extensionInfo.foldAlertExtension.alerts" :key="subAlert.id" @click="alertChildBlockClicked(alertInfo,subAlert)">
              <template #title>
                <!--**子提示简要信息-->
                <div style="display: flex;flex-direction: row;align-items: baseline;">
                  <i class="el-collapse-item__arrow el-icon-arrow-right" :class="{'is-active':alertInfo.calculateInfo.activeSubAlertId==subAlert.id}" ></i>
                  <div>{{subAlert.errorTypeDescription}}</div>
                </div>
              </template>
              <div style="padding-left: 21px;">

                <!--**简略提示显示-->
                <div class="alertOneSection" v-show="subAlert.briefMessage" style="color:rgba(72, 72, 72, 1);">{{subAlert.briefMessage}}</div>
                <!--**修改建议显示-->
                <div class="alertOneSection sectionBorder" v-show="subAlert.modificationSuggestion">
                  <div style="rgba(18,18,18,1);margin-bottom: 5px;">修改建议：</div>
                  <div style="border-left: 2px solid #579efa;padding: 6px 6px 25px;" >
                    <div class="modifySuggestionBox" >
                      <div  v-html="convertTextNewLine(subAlert.modificationSuggestion)" ref="subAlertModificationSuggestionText"></div>
                      <el-button type="primary" size="mini" @click.stop="copyText(subAlert.modificationSuggestion)" style="position: absolute;right: 0px;bottom: 3px;padding: 0 5px;font-size: 12px;">复制</el-button>
                    </div>
                  </div>
                </div>
                <!--**详细信息显示-->
                <div  v-show="subAlert.calculateInfo.expandShowMore">
                  <div
                      v-html="getRealShowAlertMessage(subAlert)">
                  </div>
                </div>
                <!--**法律依据显示-->
                <div v-if="subAlert.calculateInfo.expandShowMore" >
                  <!--**法律依据显示-->
                  <div class="alertOneSection sectionBorder" v-if="subAlert.legalBasis" style="font-size:12px;color:rgb(140, 139, 139);" v-html="convertLegalTextToHtml(subAlert.legalBasis)"></div>
                </div>

                <div style="display:flex;justify-content: space-between;flex-direction: row;margin-top:6px;">
                  <div>
                    <div  style="cursor:pointer;" v-if="expandCanShowMore(subAlert)" @click.stop="subAlert.calculateInfo.expandShowMore=!subAlert.calculateInfo.expandShowMore">
                      <template v-if="!subAlert.calculateInfo.expandShowMore">
                        <el-icon class="el-icon el-icon-arrow-down" style="margin-right: 8px;"></el-icon>
                        更多
                      </template>
                      <template v-else>
                        <el-icon class="el-icon el-icon-arrow-up" style="margin-right: 8px;"></el-icon>
                        收起
                      </template>
                    </div>
                  </div>

                  <div class="alert-icon-button-container">
                    <i class="el-icon-delete" v-show="!alertInfo.ignore" @click.stop="ignoreAlert(subAlert,true)"></i>
                    <span class="feed-back-btn" v-show="teamInfo && teamInfo.debugEnabled" @click="feedBack(subAlert)">反馈</span>
                  </div>

                </div>
              </div>

            </el-collapse-item>
          </el-collapse>
        </el-scrollbar>
      </div>

      <!--如果不是是折叠类型的提示-->
      <div class="ue-alert-wrapper-left-active" v-else>
        <!--展开共有title-->
        <!--**有errorTypeDescription的按照新的排版-->
        <template v-if="alertInfo.errorTypeDescription">
          <div class="noticeIntro" style="margin-top: 8px; margin-bottom: 14px;">
            <i class="meta-point meta-point-medium" :class="[getAlertBackgroundColorClass(alertInfo)]"></i>
            <span class="alertItemExpandErrorType" style="margin-left: 14px">{{ alertInfo.errorTypeDescription  }}</span>
            <span class="feed-back-btn" v-if="teamInfo && teamInfo.debugEnabled" @click="feedBack(alertInfo)">反馈</span>
          </div>
        </template>
        <!--没有errorTypeDescription的按照老的排版-->
        <template v-else>
          <div class="noticeIntro" style="margin-top: 8px; margin-bottom: 14px;">
            <i class="meta-point meta-point-medium" :class="[getAlertBackgroundColorClass(alertInfo)]"></i>
            <span class="alertItemExpandErrorType" style="margin-left: 14px">{{ getErrorTypeDesc(alertInfo) }}</span>
            <i class="meta-point meta-point-small background-color-gray" style="margin: 0 5px;"
               v-if="isShowSpacePoint(alertInfo)"></i>
            <span class="alertItemExpandAlertType">{{ getAlertTypeDesc(alertInfo) }}</span>

          </div>
        </template>

        <!--展开共有title结束-->
        <!--**简略提示显示-->
        <div class="alertOneSection" v-if="alertInfo.briefMessage" style="color:rgba(72, 72, 72, 1);">{{alertInfo.briefMessage}}</div>
        <!--**修改意见显示-->
        <div class="alertOneSection sectionBorder" v-if="alertInfo.modificationSuggestion">
          <div style="rgba(18,18,18,1);margin-bottom: 5px;">修改建议：</div>
          <div style="border-left: 2px solid #579efa;padding: 6px 6px;" >
            <div class="modifySuggestionBox" >
              <div  v-html="convertTextNewLine(alertInfo.modificationSuggestion)" ref="modificationSuggestionText"></div>
              <el-button type="primary" size="mini" @click.stop="copyText(alertInfo.modificationSuggestion)">复制</el-button>
            </div>
          </div>
        </div>

        <!--**展开第二阶段开始-->
        <!--**详细信息显示开始-->
        <div class="alertOneSection" style="color: rgba(72,72,72,1);">
          <!--前置审批展开特有部分-->
          <template v-if="alertInfo.calculateInfo.expandDisplayType === ExpandDisplayType.MODEL1">
            <div class="dictionaryInfoContainer">
              {{ alertInfo.calculateInfo.expandDisplayModelInfo1.data.basis }}
            </div>
            <div class="dictionaryInfoContainer"
            >
              <template
                  v-if="alertInfo.calculateInfo.expandDisplayModelInfo1.data.content.length>100&&!alertInfo.calculateInfo.expandDisplayModelInfo1.showCompleteContent">
                {{ alertInfo.calculateInfo.expandDisplayModelInfo1.data.content.substring(0, 100) }}
                <el-link type="primary" style="font-weight: 900" href="javascript:void(0)"
                         @click="alertInfo.calculateInfo.expandDisplayModelInfo1.showCompleteContent=true">
                  ...
                </el-link>
              </template>
              <template v-else>
                {{ alertInfo.calculateInfo.expandDisplayModelInfo1.data.content }}
              </template>

            </div>
            <el-image
                v-if="alertInfo.calculateInfo.expandDisplayModelInfo1.data.thumbnail"
                style="width: 100%;"
                :src="alertInfo.calculateInfo.expandDisplayModelInfo1.data.thumbnail"
                :preview-src-list="[alertInfo.calculateInfo.expandDisplayModelInfo1.data.thumbnail]">
            </el-image>
          </template>
          <!--前置审批展开特有部分结束-->
          <!--BlockList类型展开特有部分-->
          <template v-else-if="alertInfo.calculateInfo.expandDisplayType === ExpandDisplayType.MODEL_BLOCK_LIST">
            <div v-for="block in alertInfo.calculateInfo.expandDisplayModelInfo2">
              <div class="dictionaryInfoContainer color_block" :class="{pointer:blockPointerClass(block)}"
                   @click.stop="alertChildBlockClicked(alertInfo,block)">
                <span v-if="block.message_type === 'miss'">✕ </span>
                <span v-else-if="block.message_type === 'hit'">✔ </span>
                {{ block.text }}
              </div>
            </div>
          </template>
          <!--BlockList类型展开特有部分结束-->
          <!--普通错误展开部分-->
          <template v-else>
            <!--**如果不是合同错误，直接显示-->
            <div v-if="alertInfo.errorType!==ErrorType.CONTRACT_RISK">
              <!--默认展开第二部分-->
              <!--如果有sourceText-->
              <div :class="{'replaceNoticeContainer':true,'defaultCursor':alertInfo.alertType === AlertType.NO_OPERATION}"
                   v-if="alertInfo.sourceText"
                   @click.stop="playRemoveHighlineAnimation(alertInfo)"
              >
                                            <span class="replaceSourceText"
                                                  v-if="alertInfo.alertType === AlertType.NO_OPERATION && alertInfo.errorType!=ErrorType.PUNCTUATION_MISUSE"
                                                  :title="alertInfo.sourceText">{{ alertInfo.sourceText }}</span>

                <span v-else-if="alertInfo.alertType === AlertType.INSERT_BEFORE">
                                                <span class="insertReplaceText">{{ alertInfo.replaceText }}</span>
                                                <span class="normalText">{{ alertInfo.sourceText }}</span>
                                            </span>

                <span v-else-if="alertInfo.alertType === AlertType.INSERT_AFTER">
                                                <span class="normalText">{{ alertInfo.sourceText }}</span>
                                                <span class="insertReplaceText">{{ alertInfo.replaceText }}</span>
                                            </span>

                <span v-else-if="alertInfo.alertType === AlertType.DELETE">
<!--                                              <span class="normalText" v-if="alertInfo.expandBeforeText">{{alertInfo.expandBeforeText}}</span>-->
                                              <span class="deleteWords deleteText">&nbsp;{{ alertInfo.sourceText }}&nbsp;</span>
                  <!--                                              <span class="normalText" v-if="alertInfo.expandAfterText">{{alertInfo.expandAfterText}}</span>-->
                                            </span>

                <span class="twoWayReplaceSourceText"
                      v-else-if="alertInfo.errorType === ErrorType.WORD_ORDER_ERROR && alertInfo.alertType === AlertType.REPLACE"> {{ getShowSourceText(alertInfo) }}</span>

                <span v-else-if="alertInfo.errorType===ErrorType.PUNCTUATION_MISUSE">
<!--                                              <span class="normalText" v-if="alertInfo.expandBeforeText">{{alertInfo.expandBeforeText}}</span>-->
                                              <div
                                                  class="punctuationExtend">{{ alertInfo.sourceText.replace(" ", "&nbsp;") }}</div>
                  <!--                                              <span class="normalText" v-if="alertInfo.expandAfterText">{{alertInfo.expandAfterText}}</span>-->
                                            </span>

                <span :class="{'noOperationAdaptiveText': alertInfo.errorType === ErrorType.GRAMMAR_ERROR,
                                      'replaceSourceText': alertInfo.errorType !== ErrorType.GRAMMAR_ERROR}"
                      v-else>{{ alertInfo.sourceText }}</span>

                <div class="alert-go-right"
                     v-if="alertInfo.replaceText && alertInfo.errorType !== ErrorType.WORD_ORDER_ERROR && alertInfo.alertType === AlertType.REPLACE && alertInfo.errorType !== ErrorType.GRAMMAR_ERROR"></div>

                <div class="alert-two-way"
                     v-if="alertInfo.replaceText && alertInfo.errorType === ErrorType.WORD_ORDER_ERROR && alertInfo.alertType === AlertType.REPLACE && alertInfo.errorType !== ErrorType.GRAMMAR_ERROR"></div>
                <span
                    v-if="alertInfo.errorType===ErrorType.PUNCTUATION_MISUSE && alertInfo.alertType === AlertType.REPLACE">
<!--                                              <span class="normalText" v-if="alertInfo.expandBeforeText">{{alertInfo.expandBeforeText}}</span>-->
                                              <div class="replaceText">{{ getShowReplaceText(alertInfo) }}</div>
                  <!--                                              <span class="normalText" v-if="alertInfo.expandAfterText">{{alertInfo.expandAfterText}}</span>-->
                                            </span>
                <div
                    class="replaceText"
                    v-else-if="alertInfo.replaceText && alertInfo.alertType === AlertType.REPLACE && alertInfo.errorType!== ErrorType.GRAMMAR_ERROR"
                >{{ getShowReplaceText(alertInfo) }}
                </div>
              </div>
              <!--默认展开第二部分结束-->

              <!--默认展开第三部分-->
              <!--如果是语法错误-->
              <div :class="{'replaceNoticeContainer':true,'defaultCursor':alertInfo.alertType === AlertType.NO_OPERATION}"
                   v-if="alertInfo.errorType === ErrorType.GRAMMAR_ERROR"
                   @click.stop="playRemoveHighlineAnimation(alertInfo)">
                                                <span
                                                    :class="{'noOperationAdaptiveText':true,'grayFont':alertInfo.replaceText === null}">
                                                    {{ getShowReplaceText(alertInfo) }}
                                                </span>
              </div>
              <!--如果是自定义批注信息-->
              <div
                  class="customCommentInfo"
                  v-else-if="alertInfo.errorType === ErrorType.CUSTOM_COMMENT" contenteditable="true"
                  data-placeholder="请输入自定义批注备注信息" @blur="saveCustomCommentMark(event,alertInfo)">
                {{ alertInfo.alertMessage }}
              </div>
              <!--如果不是非标条款-->
              <div
                  v-else-if="alertInfo.errorType!==ErrorType.NONSTANDARD_CLAUSE"
                  v-html="getRealShowAlertMessage(alertInfo)">
              </div>
              <!--默认展开第三部分结束-->


              <!--默认展开第四部分-->
              <div >
                <!--如果explanation有值-->
                <div v-if="alertInfo.explanation"
                     class="dictionaryInfoContainer"
                     v-html="alertInfo.explanation">
                </div>
                <div v-if="alertInfo.calculateInfo.clauseType"
                     class="dictionaryInfoContainer"
                >
                  {{ '条款类型:' + alertInfo.calculateInfo.clauseType }}
                </div>
              </div>
              <!--默认展开第四部分结束-->
            </div>
            <!--如果是合同错误，则需要先隐藏，点击展开时才显示。-->
            <div v-else v-show="alertInfo.calculateInfo.expandShowMore">
              <div
                  v-html="getRealShowAlertMessage(alertInfo)">
              </div>
            </div>
          </template>
          <!--普通错误展开部分结束-->
        </div>
        <!--**详细信息显示结束-->
        <div v-if="alertInfo.calculateInfo.expandShowMore" >
          <!--**法律依据显示-->
          <div class="alertOneSection sectionBorder" v-if="alertInfo.legalBasis" style="font-size:12px;color:rgb(140, 139, 139);" v-html="convertLegalTextToHtml(alertInfo.legalBasis)"></div>
        </div>

        <!--**展开第二阶段结束-->
        <!--最下面工具栏显示开始-->
        <div style="display:flex;justify-content: space-between;flex-direction: row;margin-top:6px;">
          <div>
            <div  style="cursor:pointer;" v-if="expandCanShowMore(alertInfo)" @click.stop="alertInfo.calculateInfo.expandShowMore=!alertInfo.calculateInfo.expandShowMore">
              <template v-if="!alertInfo.calculateInfo.expandShowMore">
                <el-icon class="el-icon el-icon-arrow-down" style="margin-right: 8px;"></el-icon>
                更多
              </template>
              <template v-else>
                <el-icon class="el-icon el-icon-arrow-up" style="margin-right: 8px;"></el-icon>
                收起
              </template>

            </div>
          </div>

          <div class="alert-icon-button-container">
            <i class=" el-icon-info" v-if="alertInfo.calculateInfo.clauseText" title="查看标准条款"
               @click.stop="showClauseTextDialog(alertInfo.calculateInfo.clauseText)"></i>
            <i class="el-icon-delete" v-if="!alertInfo.ignore" @click.stop="ignoreAlert(alertInfo,true)"></i>
          </div>

        </div>
        <!--最下面工具栏显示结束-->
      </div>

      <!--右侧操作图标-->
      <!--展开操作图标-->
      <!--            <div class="ue-alert-wrapper-right">-->
      <!--                &lt;!&ndash;                                        <img src="https://uranus-static.oss-accelerate.aliyuncs.com/xiezuocat/open.png"/>&ndash;&gt;-->
      <!--                <svg xmlns="http://www.w3.org/2000/svg" class="svg-icon-path-icon fill" viewBox="0 0 1024 1024" width="16" height="16"><path d="M208.61 758.21a33.13 33.13 0 0 0 33.13 33.14h540.52a33.13 33.13 0 0 0 33.13-33.14v-234a303.39 303.39 0 1 0-606.78 0z m58-234A245.41 245.41 0 0 1 512 278.79 245.41 245.41 0 0 1 757.41 524.2v209.16H266.59z" p-id="7155"></path><path d="M147.52 830.7m8.28 0l712.41 0q8.28 0 8.28 8.28l0 41.43q0 8.28-8.28 8.28l-712.41 0q-8.28 0-8.28-8.28l0-41.43q0-8.28 8.28-8.28Z" p-id="7156"></path><path d="M483.01 31.32m8.28 0l41.43 0q8.28 0 8.28 8.28l0 101.81q0 8.28-8.28 8.28l-41.43 0q-8.28 0-8.28-8.28l0-101.81q0-8.28 8.28-8.28Z" p-id="7157"></path><path d="M163.476445 187.683254m5.854845-5.854844l29.295433-29.295434q5.854844-5.854844 11.709689 0l71.990541 71.990541q5.854844 5.854844 0 11.709688l-29.295434 29.295434q-5.854844 5.854844-11.709688 0l-71.990541-71.990541q-5.854844-5.854844 0-11.709688Z" p-id="7158"></path><path d="M48.11 466.21m8.28 0l101.81 0q8.28 0 8.28 8.28l0 41.43q0 8.28-8.28 8.28l-101.81 0q-8.28 0-8.28-8.28l0-41.43q0-8.28 8.28-8.28Z" p-id="7159"></path><path d="M857.52 466.21m8.28 0l101.81 0q8.28 0 8.28 8.28l0 41.43q0 8.28-8.28 8.28l-101.81 0q-8.28 0-8.28-8.28l0-41.43q0-8.28 8.28-8.28Z" p-id="7160"></path><path d="M735.815253 230.389625m5.854844-5.854844l71.990542-71.990542q5.854844-5.854844 11.709688 0l29.295434 29.295434q5.854844 5.854844 0 11.709688l-71.990542 71.990542q-5.854844 5.854844-11.709688 0l-29.295434-29.295434q-5.854844-5.854844 0-11.709688Z" p-id="7161"></path></svg>-->
      <!--                <i class="el-icon-delete" v-if="!alertInfo.ignore" @click.stop="ignoreAlert(alertInfo,true)"></i>-->
      <!--                <img v-else src="/images/revert.png" @click.stop="ignoreAlert(alertInfo,false)"/>-->
      <!--                &lt;!&ndash;                                        <img v-if="!alertInfo.ignore" src="/images/cancel.png" @click.stop="ignoreAlert(alertInfo,true)"/>&ndash;&gt;-->
      <!--                &lt;!&ndash;                                        <img v-else src="/images/accepted.png" @click.stop="ignoreAlert(alertInfo,false)"/>&ndash;&gt;-->
      <!--                <img v-if="alertInfo.errorType===ErrorType.NONSTANDARD_CLAUSE" src="/images/add.png"-->
      <!--                     @click.stop="addClauseFunc(alertInfo)"/>-->
      <!--                <i class="el-icon-info" v-if="alertInfo.calculateInfo.clauseText" title="查看标准条款"-->
      <!--                   @click.stop="showClauseTextDialog(alertInfo.calculateInfo.clauseText)"></i>-->
      <!--            </div>-->
      <!--展开操作图标结束-->
    </div>
  </div>
</template>

<script>
import {
  AlertDisplayStatus,
  AlertExtensionType, AlertMsgClickType,
  AlertType, BlockMessageType,
  ErrorType,
  ExpandDisplayType
} from "../../constant/alert";
import config from "../../config";
import {copyStringToClipboard} from "@/util";
import {ElMessageBox} from "element-plus";
import {contractCheckFeedBack} from "../../api/api";

export default {
  name: "ContractAlertBox",
  props: ['alertInfo','feedBackHash'],
  data: function () {
    return {
      ErrorType: ErrorType,
      AlertType: AlertType,
      ExpandDisplayType: ExpandDisplayType,
      AlertDisplayStatus: AlertDisplayStatus,
      teamInfo:JSON.parse(sessionStorage.getItem("teamInfo"))
    };
  },
  computed:{

  },
  beforeMount() {
    // this.parseAlert(this.alertInfo);
  },
  mounted() {
    this.$parent.addContractAlterBoxRef(this);
  },
  unmounted() {
    this.$parent.removeContractAlterBoxRef(this);
  },
  methods: {
    parseAlert(alert){
      this.parseFoldAlert(alert)
    },
    parseFoldAlert: function (alert) {
      if(!alert.extensionInfo){
        return;
      }
      if (alert.extensionInfo["foldAlertExtension"]) {
        for (let subAlert of alert.extensionInfo["foldAlertExtension"]["alerts"]) {
          subAlert.calculateInfo = {};
        }
      }
    },
    expandCanShowMore:function (alertInfo){
      // 如果有法律依据，则返回true。
      if(alertInfo.legalBasis){
        return true;
      }
      // 如果是合同风险
      if(alertInfo.errorType===ErrorType.CONTRACT_RISK){
        // 如果有详细信息，
        if(alertInfo.alertMessage){
          //不是前置审批和blockList都可以展开。
          return !(alertInfo.calculateInfo.expandDisplayType === ExpandDisplayType.MODEL1||alertInfo.calculateInfo.expandDisplayType === ExpandDisplayType.MODEL_BLOCK_LIST);
        }
      }
      return false;
    },

    blockPointerClass: function (alertChildBlock) {
      // 注释掉miss block的clauseText功能
      // return alertChildBlock.message_type === BlockMessageType.HIT || alertChildBlock.calculateInfo.clauseText;
      return alertChildBlock.message_type === BlockMessageType.HIT;
    },
    alertChildBlockClicked: function (alert, childBlock) {
      console.log('contractAlertBox alertChildBlockClicked**************', alert, childBlock)
      this.$emit('alert-child-block-clicked', {alert: alert, childBlock: childBlock})
    },
    getDomElement: function () {
      return this.$refs['box']
    },
    alertClick: function (alertObj) {
      this.$emit('alert-click', alertObj)
    },
    playRemoveHighlineAnimation: function (alertInfo) {
      let self = this
      self.$emit('accept-alert', alertInfo)
    },

    isShowSpacePoint: function (alert) {
      if (alert.errorType === ErrorType.LAW_DEFINE) {
        return false
      }
      return true
    },
    getAlertBorderClass: function (alert) {
      let borderClass = '';
      if (ErrorType.TYPO === alert.errorType) {
        borderClass = (alert.calculateInfo.status === AlertDisplayStatus.EXPAND ? 'alertItemExpandBorderLeft_new_1' : 'alertItemCollapseBorderLeft_new_1');
      } else if (ErrorType.PUNCTUATION_MISUSE === alert.errorType) {
        borderClass = (alert.calculateInfo.status === AlertDisplayStatus.EXPAND ? 'alertItemExpandBorderLeft_new_2' : 'alertItemCollapseBorderLeft_new_2');
      } else if (ErrorType.WORD_ORDER_ERROR === alert.errorType) {
        borderClass = (alert.calculateInfo.status === AlertDisplayStatus.EXPAND ? 'alertItemExpandBorderLeft_new_3' : 'alertItemCollapseBorderLeft_new_3');
      } else if (ErrorType.SYNONYMOUS_CONFUSION === alert.errorType || ErrorType.CONTRACT_RISK === alert.errorType) {
        borderClass = (alert.calculateInfo.status === AlertDisplayStatus.EXPAND ? 'alertItemExpandBorderLeft_new_4' : 'alertItemCollapseBorderLeft_new_4');
      } else if (ErrorType.GRAMMAR_ERROR === alert.errorType || ErrorType.CUSTOM_COMMENT === alert.errorType) {
        borderClass = (alert.calculateInfo.status === AlertDisplayStatus.EXPAND ? 'alertItemExpandBorderLeft_5' : 'alertItemCollapseBorderLeft_5');
      } else if (alert.errorType && alert.errorType > 100 || ErrorType.NONSTANDARD_CLAUSE === alert.errorType) {
        borderClass = (alert.calculateInfo.status === AlertDisplayStatus.EXPAND ? 'alertItemExpandBorderLeft_6' : 'alertItemCollapseBorderLeft_6');
      } else if (ErrorType.STANDARD === alert.errorType) {
        borderClass = (alert.calculateInfo.status === AlertDisplayStatus.EXPAND ? 'alertItemExpandBorderLeft_new_5' : 'alertItemCollapseBorderLeft_new_5');
      }
      let result = {}
      if (borderClass === '') {
        return result;
      }
      result[borderClass] = true;
      return result;
    },
    getAlertBackgroundColorClass: function (alert) {
      return 'alert-background-color-' + this.getAlertColorNum(alert);
    },
    getAlertColorNum: function (alert) {
      if (ErrorType.TYPO === alert.errorType) {
        return 1;
      } else if (ErrorType.PUNCTUATION_MISUSE === alert.errorType) {
        return 2;
      } else if (ErrorType.WORD_ORDER_ERROR === alert.errorType) {
        return 3;
      } else if (ErrorType.SYNONYMOUS_CONFUSION === alert.errorType || ErrorType.CONTRACT_RISK === alert.errorType||ErrorType.FOLD_ALERT === alert.errorType) {
        return 4;
      } else if (ErrorType.GRAMMAR_ERROR === alert.errorType || ErrorType.CUSTOM_COMMENT === alert.errorType) {
        return 5;
      } else if (alert.errorType && alert.errorType > 100 || ErrorType.NONSTANDARD_CLAUSE === alert.errorType) {
        return 6;
      } else if (ErrorType.STANDARD === alert.errorType) {
        return 7;
      }
      return 0;
    },
    getLawDefineDesc: function (lawDefineStr) {
      let lawDefineDetail = JSON.parse(lawDefineStr)
      let lawSourceUrl = config.neptuneDomain + "/#/searchlist?searchTag=laws&searchKeys=" + lawDefineDetail.source + "&sf=top&defaultTag=laws"
      return "【来源法规】：<a href='" + lawSourceUrl + "' target='_blank'>" + lawDefineDetail.source + "</a></br>" + "【效力级别】：" + lawDefineDetail.level + "</br>" + "【定义】：" + lawDefineDetail.define
    },
    getAlertSourceTextDesc: function (alertEntity) {
      return alertEntity.sourceText;
      // let sourceText = alertEntity.sourceText
      // if (alertEntity.errorType === ErrorType.PUNCTUATION_MISUSE && alertEntity.sourceText.length === 1) {
      //     return alertEntity.expandBeforeText + sourceText + alertEntity.expandAfterText
      // } else {
      //     return sourceText
      // }
    },
    isShowReplaceText(alertEntity) {
      return alertEntity.alertType === AlertType.REPLACE && alertEntity.errorType !== ErrorType.GRAMMAR_ERROR
    },
    getAlertReplaceTextDesc: function (alertEntity) {
      let replaceText = alertEntity.replaceText
      if (alertEntity.errorType === ErrorType.PUNCTUATION_MISUSE && alertEntity.sourceText.length === 1) {
        return alertEntity.expandBeforeText + replaceText + alertEntity.expandAfterText
      } else {
        return replaceText
      }
    },
    getCollapseLeftText: function (alert) {
      if (alert.errorType === ErrorType.CONTRACT_RISK || alert.errorType === ErrorType.NONSTANDARD_CLAUSE) {
        //如果是合同错误，则左边显示 错误类型，如主体检查
        return this.getErrorTypeDesc(alert)
      } else {
        //如果不是合同错误，则第一部分显示sourceText
        return this.getAlertSourceTextDesc(alert)
      }
    },
    getCollapseMediumText: function (alert) {
      if (alert.errorType === ErrorType.CONTRACT_RISK || alert.errorType === ErrorType.NONSTANDARD_CLAUSE) {
        //如果是合同错误，合同错误可能分为三级，eg: 合同检查->主体检查->公司名变更
        //如果有错误类型子描述，则显示错误类型子描述（比如例子中的公司名变更）
        if (alert.calculateInfo.errorTypeSubDescription) {
          return alert.calculateInfo.errorTypeSubDescription
        } else {
          //如果没有，就显示建议检查
          return this.getAlertTypeDesc(alert);
        }
      }
      return this.getErrorTypeDesc(alert)
    },
    //获取建议的子标题
    getCollapseRightText: function (alert) {
      this.getAlertReplaceTextDesc(alert)
    },
    getAlertTypeDesc: function (alert) {
      let alertType = alert.alertType;
      switch (alertType) {
        case AlertType.OTHER:
          return '其它错误';
        case AlertType.INSERT_BEFORE:
          return '建议划线处前添加';
        case AlertType.INSERT_AFTER:
          return '建议划线处后添加';
        case AlertType.DELETE:
          return '建议删除';
        case AlertType.REPLACE:
          if (alert.calculateInfo.status === 0 && alert.errorType !== ErrorType.GRAMMAR_ERROR) {
            if (alert.errorType == ErrorType.WORD_ORDER_ERROR) {
              return '建议交换为';
            } else {
              return '建议替换为';
            }
          } else {
            if (alert.errorType == ErrorType.WORD_ORDER_ERROR) {
              return '建议交换';
            } else {
              return '建议替换';
            }
          }
        case AlertType.NO_OPERATION:
          if (alert.errorType === ErrorType.LAW_DEFINE) {
            if (alert.calculateInfo.status === 0) {
              return '法律定义'
            }
            return ''
          } else if (alert.errorType === ErrorType.SERIAL_ERROR && alert.calculateInfo.status === 0) {
            return '序号问题'
          }
          if (alert.calculateInfo.errorTypeSubDescription) {
            return alert.calculateInfo.errorTypeSubDescription
          }
          return '建议检查';
        case AlertType.WRAP:
          return '缺少符号';
        default:
          return '其它错误'
      }
    },
    getErrorTypeDesc: function (alert) {
      if (alert.errorTypeDescription) {
        return alert.errorTypeDescription
      }
      switch (alert.errorType) {
        case ErrorType.ALL:
          return '所有提示';
        case ErrorType.OTHER:
          return '其它错误';
        case ErrorType.TYPO:
          return '字词错误';
        case ErrorType.PUNCTUATION_MISUSE:
          return '标点误用';
        case ErrorType.WORD_ORDER_ERROR:
          return '语序问题';
        case ErrorType.SYNONYMOUS_CONFUSION:
          return '同义混淆';
        case ErrorType.GRAMMAR_ERROR:
          return '语法问题';
        case ErrorType.DATE_ERROR:
          return '日期错误';
        case ErrorType.AMOUNT_ERROR:
          return '金额错误';
        case ErrorType.BRAND_ERROR:
          return '专有名词规范';
        case ErrorType.DISUNITY_ERROR:
          return '重复定义';
        case ErrorType.SERIAL_ERROR:
          return '序号问题';
        case ErrorType.LAW_DEFINE:
          return '法律定义';
        case ErrorType.STANDARD:
          return '规范检查';
        case ErrorType.CONTRACT_RISK:
          return '合同风险';
        case ErrorType.NONSTANDARD_CLAUSE:
          return '非标条款';
        case ErrorType.CUSTOM_COMMENT:
          return '自定义批注';
        default:
          return '其它错误';
      }
    },
    getShowSourceText: function (alertInfo) {
      let alertEntity = alertInfo
      let sourceText = alertEntity.sourceText
      let replaceText = alertEntity.replaceText
      if (AlertType.REPLACE === alertEntity.alertType && ErrorType.WORD_ORDER_ERROR === alertEntity.errorType) {
        // 如果是语序错误。
        let point = replaceText.indexOf(sourceText.substring(0, 1))
        return replaceText.substring(point)
      } else {
        return sourceText
      }
    },
    getShowReplaceText: function (alertInfo) {
      let alertEntity = alertInfo
      let sourceText = alertEntity.sourceText
      let replaceText = alertEntity.replaceText
      if (AlertType.REPLACE === alertEntity.alertType && ErrorType.WORD_ORDER_ERROR === alertEntity.errorType) {
        // 如果是语序错误。
        let point = replaceText.indexOf(sourceText.substring(0, 1))
        return replaceText.substring(0, point)
      } else if (alertEntity.errorType === ErrorType.GRAMMAR_ERROR) {
        if (alertEntity.replaceText === null) {
          return "加载中"
        }
      }
      return replaceText
    },
    //获取真正显示的提示信息
    getRealShowAlertMessage: function (alertInfo) {
      const msg = alertInfo.alertMessage
      if (alertInfo.errorType === ErrorType.LAW_DEFINE) {
        return this.getLawDefineDesc(msg)
      }
      //获取alertMessage的click信息
      if (!alertInfo.extensionInfo) {
        return msg;
      }
      if (alertInfo.extensionInfo[AlertExtensionType.ALERT_MSG_CLICK]) {
        let clickInfo = alertInfo.extensionInfo[AlertExtensionType.ALERT_MSG_CLICK]
        const keyIndex = msg.indexOf(clickInfo.key)
        const textBeforeKey = msg.substring(0, keyIndex)
        const textAfterKey = msg.substring(keyIndex + clickInfo.key.length)
        return `${textBeforeKey}${this.generateAlertMessageKeyClickElement(clickInfo)}${textAfterKey}`
      }
      return msg;
    },
    generateAlertMessageKeyClickElement: function (clickInfo) {
      if (clickInfo.type === AlertMsgClickType.OPEN_COMPANY_META_INFO) {
        return `<a href='javascript:void(0)' class="color_blue" onclick="window.parent.openCompanyMetaInfo('${clickInfo.companyName}','${clickInfo.tag}')">${clickInfo.key}</a>`;
      }
      return clickInfo.key
    },
    showClauseTextDialog: function (clauseTextString) {
      this.$emit('show-clause-text', clauseTextString);
    },
    convertTextNewLine:function (text){
      if(!text){
        return '';
      }
      return text.replace(/\n/g,'<br>')
    },
    convertLegalTextToHtml:function (text){
      let res = this.convertTextNewLine(text);
      const aroundReplacer = (str) => `<b>${str}</b>`;
      return res.replace(/《[^《]*》\s*第.{1,9}条/g,aroundReplacer).replace(/(第.{1,9}条)/g,aroundReplacer)
    },
    copyText: function (text) {
      copyStringToClipboard(text);
      this.$message.success('复制成功');
    },
    feedBack:function (alert){
      ElMessageBox.confirm('这条审查结果正确吗?','反馈',{
        showCancelButton:true,
        cancelButtonText:'不正确',
        confirmButtonText:'正确',
        distinguishCancelAndClose:true
      }).then(()=>{
            let param={
              feedBackHash:this.feedBackHash,
              alert:alert,
              url:document.location.href,
              correct:true
            }
            contractCheckFeedBack(param).then(res=>{
              if(res.status===200){
                this.$message.success('反馈成功');
              }
            })
      }
      ).catch((e)=>{
        if(e==='cancel'){
          let param={
            feedBackHash:this.feedBackHash,
            alert:alert,
            url:document.location.href,
            correct:false
          }
          contractCheckFeedBack(param).then(res=>{
            if(res.status===200){
              this.$message.success('反馈成功');
            }
          })
        }
      })


    }

  },
}
</script>
<style>
.foldAlert .el-scrollbar__bar.is-vertical {
  display: none;
}
.foldAlert{
  padding-bottom: 20px;
}
.foldAlert  .el-collapse-item__header{
  border:none!important;
  height: auto;
  line-height: 20px;
}
.foldAlert  .el-collapse-item__content{
  padding-bottom: 8px;
}
.foldAlert  .el-collapse-item__header > i{
  display:none;
}
.foldAlert  .el-collapse{
  border:none!important;
}
/*.foldAlert .el-collapse-item{*/
/*  height: 30px;*/
/*  line-height: 30px;*/
/*}*/
</style>

<style scoped lang="scss">
.collapseTitle{
  color: rgb(72, 72, 72);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.alertOneSection{
  //margin-left: 26px;
  position: relative;
  display: inline-block;
  flex-direction: row;
  justify-content: space-between;
  //margin-right: 15px;
  /* line-height: 45px; */
  padding: 6px 0;
}
.sectionBorder{
  border-bottom: 1px solid #EBEBEB;
}
.modifySuggestionBox{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.alert-icon-button-container i {
  cursor: pointer;
  margin-right: 10px;
}

.alert-icon-button-container i:last-child {
  margin-right: 0;
}

.meta-point {
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}

.meta-point-medium {
  width: 10px;
  height: 10px;
}

.meta-point-small {
  width: 4px;
  height: 4px;
}

.background-color-gray {
  background-color: rgb(147, 147, 147);
}

.alert-background-color-1 {
  background-color: #f86b65;
}

.alert-background-color-2 {
  background-color: #ffc785;
}

.alert-background-color-3 {
  background-color: #5ec9db;
}

.alert-background-color-4 {
  background-color: #579efa;
}

.alert-background-color-5 {
  background-color: #EE6BBB;
}

.alert-background-color-6 {
  background-color: #f0cd6c;
}

.alert-background-color-7 {
  background-color: #968dff;
}
</style>
