<template>
  <div class="comment-invisible-tip" ref="tipSelf"
       :style="{
    top:position.top+'px',
    left:position.left+'px',
    bottom:position.bottom+'px',
    right:position.right+'px'
  }"
  >
    <svg-icon class="cit-arror" :class="{bottom: placement == 'bottom'}" name="upperArror_long"></svg-icon>
    <span style="vertical-align: middle">
      跳转至{{placement == 'top' ? "上" : "下"}}条评论
    </span>
    </div>
</template>

<script>
import {debounce, DomEventListenerManager} from "../../util";
import SvgIcon from "../SvgIcon/svgIcon";

export default {
  name: "CommentsInvisibleTip",
  components: {SvgIcon},
  props:{
    placement:{ //'top' 'bottom'
      required:true,
    },
    position:{
      required:false,
      default:{
        top:0,
        left:0,
      }
    }
  },
  data(){
    return {
    }
  },

  mounted() {

  },
  methods:{

  },

}
</script>

<style scoped>
.comment-invisible-tip{
  position: absolute;
  display: inline-block;
  white-space: pre;
  background: white;
  border-radius: 999px;
  box-shadow: 0 5px 3px -3px var(--gray-2),0 1px 3px 3px var(--gray-1);
  line-height: 3;
  padding: 0 3ch;
  user-select: none;
  cursor:pointer;
  transition: .3s box-shadow;
  font-size: 1rem;
}
.cit-arror{
  transform: translateY(-1px);
  vertical-align: middle;
}
.cit-arror.bottom{
  transform: rotate(180deg) translateY(-1px);
}
.comment-invisible-tip:hover{
  box-shadow:0 5px 3px -3px var(--gray-2),0px 1px 3px 3px rgb(222, 223, 224);
}
</style>
