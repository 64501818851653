import {colorLog, singleListenerManager} from "../../util";
export const checkEvents = {
    ALERT_CLICK:'contractCheckerAlertClick',
    ALERT_DATA_REFRESHED:'contractAlertDataRefreshed'
}
export const checkerMixin = {
    methods:{
        emitAlertClickEvent(){
            singleListenerManager.emit(checkEvents.ALERT_CLICK);
        },
        emitAlertDataRefreshedEvent(){
            singleListenerManager.emit(checkEvents.ALERT_DATA_REFRESHED);
        }
    }

}
