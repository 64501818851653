/**
 * 根据竖直显示模式来合并矩形区域
 * @param {[{top,left,width,height}]} selectRangeRects
 * @param {[{left,width}]} pagePosition
 */
export const mergeRectsByVerticalShowType = function (selectRangeRects, pagePosition) {
    // console.log('**********mergeRectsByMax')
    if (selectRangeRects.length === 0) {
        return [];
    }
    let res = {
        top: selectRangeRects[0].top,
        // 划线统一从左到右
        left: pagePosition.left + 15,
        width: pagePosition.width - 30,
        height: selectRangeRects[0].height
    };
    if (selectRangeRects.length < 2) {
        //将选区扩大，避免选区太小了，看着尴尬
        expandRectHeight(res)
        return [res];
    }
    for (let i = 0; i < selectRangeRects.length; i++) {
        let y2 = Math.max(res.top + res.height, selectRangeRects[i].top + selectRangeRects[i].height);
        res.top = Math.min(res.top, selectRangeRects[i].top);
        res.height = y2 - res.top;
    }
    //将选区扩大，避免选区太小了，看着尴尬
    expandRectHeight(res)
    return [res]
}

/**
 * 对获取到的DOMRectList里面的DOMRect进行去重，防止同一个位置出现多个选区选中,并将左右相邻很近的rect尽量连接起来。
 * @param {[{top,left,width,height}]} selectRangeRects
 */
export const mergeRectsByAdjacent = function (selectRangeRects) {
    if (selectRangeRects.length < 2) {
        return selectRangeRects
    }
    let result = [];
    let mergeRect = {
        top: selectRangeRects[0].top,
        left: selectRangeRects[0].left,
        width: selectRangeRects[0].width,
        height: selectRangeRects[0].height
    };
    for (let i = 1; i < selectRangeRects.length; i++) {
        let domEle = selectRangeRects[i]
        if (rectCanMerge(mergeRect, domEle)) {
            //如果前一个rect和现在的rect能够merge，则对它们进行融合
            let maxBottom = Math.min(mergeRect.top + mergeRect.height, domEle.top + domEle.height);
            let minTop = Math.min(mergeRect.top, domEle.top);

            let maxRight = Math.max(mergeRect.left + mergeRect.width, domEle.left + domEle.width);
            let minLeft = Math.min(mergeRect.left, domEle.left);

            mergeRect.top = minTop;
            mergeRect.height = maxBottom - minTop;
            mergeRect.left = minLeft;
            mergeRect.width = maxRight - minLeft;
        } else {
            //如果不能够merge，则将mergeRect作为一个range放到结果里面
            result.push(Object.assign({}, mergeRect));
            //并将当前rect作为mergeRect
            mergeRect.top = domEle.top;
            mergeRect.left = domEle.left;
            mergeRect.width = domEle.width;
            mergeRect.height = domEle.height;
        }
    }
    //将最后一个rect放入结果中。
    result.push(Object.assign({}, mergeRect));
    return result;
};
export  const rectCanMerge = function (rect1,rect2){
    //x方向，两个矩形的中心点的距离
    const xCenterDistance = Math.abs((rect2.left + rect2.width / 2) - (rect1.left + rect1.width / 2))
    //x方向，两个矩形的边长的一半
    const xHalfOfTotalWidth = (rect1.width + rect2.width) / 2;
    //y方向，两个矩形的中心点的距离
    const yCenterDistance = Math.abs((rect2.top + rect2.height / 2) - (rect1.top + rect1.height / 2))
    //y方向，两个矩形的边长的一半
    const yHalfOfTotalHeight = (rect1.height + rect2.height) / 2;
    //x方向可以融合的宽度差，这里把高度作为一个字的宽度，取一个字的百分之70作为可以融合的宽度差
    const mergeWidthDiff = yHalfOfTotalHeight * 0.7;
    const maxHeightDiff = yHalfOfTotalHeight * 0.1;
    //如果相交，则两个中心点之间的距离肯定小于等于该方向的边长和的一半。
    return xCenterDistance - mergeWidthDiff <= xHalfOfTotalWidth && yCenterDistance + maxHeightDiff <= yHalfOfTotalHeight;
}
function expandRectHeight (rect) {
    rect.top = Math.max(0, rect.top - 3);
    rect.height = rect.height + 6;
    return rect
}
