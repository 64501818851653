<template>
  <div class="checker-invisible-tip-group"
       ref="groupSelf"
       :style="{height:groupHeight ? groupHeight + 'px' : 0,
    top:offsetTop + 'px'
  }"
  >
    <CheckerInvisibleTip
        class="checker-tip"
        :class="{show:showTopTip}"
        ref="topTip"
        placement="top"
        :position="tipPositions.top"
        @click="handleTipClicked(true)"
    >
      {{$parent.contractAlterBoxRefs.length}}
    </CheckerInvisibleTip>
    <CheckerInvisibleTip
        class="checker-tip"
        :class="{show:showBottomTip}"
        ref="bottomTip"
        placement="bottom"
        :position="tipPositions.bottom"
        @click="handleTipClicked(false)"
    >
      {{$parent.contractAlterBoxRefs.length}}
    </CheckerInvisibleTip>
  </div>
</template>

<script>
import {colorLog, debounce, DomEventListenerManager, getBrowser, singleListenerManager} from "../../util";
import CheckerInvisibleTip from "./CheckerInvisibleTip";
import {AlertShowType, getAlertShowType} from "../../constant/alert";
import {checkEvents} from "../contract/mixin";

export default {
  name: "CheckerInvisibleTipGroup",
  components: {CheckerInvisibleTip},
  props:{
    tipPositions:{ //{top:{top:xx,left:xx},bottom:{top:xx,left:xx}}
      required:true
    },
    thresholds:{  //{top:xx,bottom:xx}
      required:true
    },
    position:{
      required:false,
      default:{
        top:0,
        bottom:0,
      }
    },
  },
  watch:{

  },
  data(){
    return {
      domEventListenerManager:new DomEventListenerManager(),
      currentPosition:{top:null,left:null,bottom:null,right:null},
      topTipPosition:{top:null,left:null,bottom:null,right:null},
      bottomTipPosition:{top:null,left:null,bottom:null,right:null},
      groupHeight:0,
      offsetTop:0,
      scrollRef:null,
      showTopTip:false,
      showBottomTip:false,
      judgeCheckerExceedThreshold:debounce(()=>{
        const contractAlterBoxRefs = this.$parent.contractAlterBoxRefs;
        if(contractAlterBoxRefs.length == 0) return;

        let bottomEdgePosition = {
          node:null,
          value:- Infinity
      };
        let topEdgePosition = {
          node:null,
          value:Infinity
        };

        contractAlterBoxRefs.forEach((ref)=>{
          const el = ref.$el;
          const {top,bottom} = el.getBoundingClientRect();
          if(top < topEdgePosition.value) {
            topEdgePosition.node = el;
            topEdgePosition.value = top
          };
          if(bottom > bottomEdgePosition.value) {
            bottomEdgePosition.node = el;
            bottomEdgePosition.value = bottom
          };
        });

        let groupTopEdge = this.currentPosition.top;
        let groupBottomEdge = this.currentPosition.bottom;
        if((bottomEdgePosition.value - groupTopEdge) < this.thresholds.top && !this.isLappedWithTip(this.topTipPosition,bottomEdgePosition.node)){
          this.showTopTip = true;
        }else{
          this.showTopTip = false;
        }
        if((groupBottomEdge - topEdgePosition.value) < this.thresholds.bottom && !this.isLappedWithTip(this.bottomTipPosition,topEdgePosition.node)){
          this.showBottomTip = true;
        }else{
          this.showBottomTip = false;
        }
      },200),
    }
  },
  created() {
    singleListenerManager.on(checkEvents.ALERT_CLICK,()=>{
      this.showBottomTip = this.showTopTip = false;
    })
    singleListenerManager.on(checkEvents.ALERT_DATA_REFRESHED,()=>{
      this.showBottomTip = this.showTopTip = false;
    })
  },
  unmounted() {
    singleListenerManager.off(checkEvents.ALERT_CLICK);
    singleListenerManager.off(checkEvents.ALERT_DATA_REFRESHED);
  },
  methods:{
    handleScroll(e){
      // colorLog.red('scroll',e.currentTarget.scrollTop);
      this.showTopTip = this.showBottomTip = false;
      this.updateOffsetTop();

      this.judgeCheckerExceedThreshold();
    },
    handleMouseWheel(e){
      let deltaY;
      if(e.deltaY){
        deltaY = e.deltaY;
      }else{
        if(e.detail < 0){
          deltaY = -96;
        }else{
          deltaY = 96;
        }
      }

      if(e.currentTarget.scrollTop === 0 && this.$parent.alertsOffsetTop < 0){
        let originScrollTop =  this.$parent.alertsOffsetTop;
        this.$parent.alertsOffsetTop = Math.min(originScrollTop - deltaY,0);
        // e.preventDefault();
        // e.stopPropagation();
      }

      const scrollerBottom = e.currentTarget.getBoundingClientRect().bottom;
      if(e.currentTarget.scrollTop === (e.currentTarget.scrollHeight - e.currentTarget.clientHeight)){

      }

      // colorLog.blue('mouseWheel',e,e.currentTarget.scrollTop);
    },
    isLappedWithTip(tipPs,node){
      let {top,left,bottom,right} = node.getBoundingClientRect();

      return (tipPs.top <= top && tipPs.bottom >= top) ||
          (tipPs.top <= bottom && tipPs.bottom >= bottom) ||
          (top <= tipPs.top && tipPs.top <= bottom) ||
          (top <= tipPs.bottom && tipPs.bottom <= bottom)
    },
    updateTipsPosition(){
      let topTip = this.$refs.topTip.$el;
      let bottomTip = this.$refs.bottomTip.$el;
      //局部上下文区块 方便赋值
      {
        let {top,bottom,left,right} = topTip.getBoundingClientRect();
        this.topTipPosition = {top,bottom,left,right};
      }
      //局部上下文区块
      {
        let {top,bottom,left,right} = bottomTip.getBoundingClientRect();
        this.bottomTipPosition = {top,bottom,left,right};
      }
    },
    handleWindowResize(){
      if(!this.$refs.groupSelf) return;
      this.updateOffsetTop();
      this.updateGroupHeight();
      this.$nextTick(()=>{
        this.updateCurrentPosition();
      })
    },
    updateGroupHeight(){
      this.groupHeight = this.scrollRef.clientHeight - this.position.top - this.position.bottom;
    },
    updateOffsetTop(){
      if(!this.scrollRef){
        this.updateScrollRef();
      }
      let exitTop = this.$parent.$el.getBoundingClientRect().top - this.scrollRef.getBoundingClientRect().top;
      this.offsetTop = this.position.top - exitTop ;
    },
    updateCurrentPosition(){
      let {top,bottom,left,right} = this.$refs.groupSelf.getBoundingClientRect();
      this.currentPosition = {top,bottom,left,right};
    },
    updateScrollRef(){
      const mouseWheelEventName = getBrowser() == 'FF' ? "DOMMouseScroll" : "mousewheel";
      this.domEventListenerManager.removeListener(this.scrollRef,mouseWheelEventName,this.handleMouseWheel);
      this.domEventListenerManager.removeListener(this.scrollRef,'scroll',this.handleScroll);

      let node = this.$refs.groupSelf;
      let nodeOverflowY = getComputedStyle(node).overflowY;
      while(nodeOverflowY != 'scroll' && nodeOverflowY != 'auto' && nodeOverflowY != 'overlay'){
        node = node.parentNode;
        nodeOverflowY = getComputedStyle(node).overflowY;
      }
      this.scrollRef = node;

      this.domEventListenerManager.registerListener(this.scrollRef,mouseWheelEventName,this.handleMouseWheel);
      this.domEventListenerManager.registerListener(this.scrollRef,'scroll',this.handleScroll);
    },

    handleTipClicked(isTop){
      const realReferenceObjs = this.$parent.alertData.filter(alertObj => {
        let alertShowType = getAlertShowType(alertObj.showType);
        if(AlertShowType.NONE !== alertShowType && AlertShowType.DOC !== alertShowType){
          return true;
        }
      })

      if(realReferenceObjs.length != 0){
        let miniObj;
        realReferenceObjs.reduce((min,alertObj) => {
          let highlightEle
          let screenMiddle = window.innerHeight / 2;
          try {
            highlightEle = this.$parent.getHighlightRef().getHighlightRef(alertObj.id)
          } catch (e) {
            this.refreshAlertData(this.$parent.alertData)
            highlightEle = this.$parent.getHighlightRef().getHighlightRef(alertObj.id)
            console.error('alertClick e:', e)
          }
          let top = Infinity;
          let bottom = -Infinity;
          if(Array.isArray(highlightEle)){
            highlightEle.forEach(el => {
              let rect = el.getBoundingClientRect();
              if(top > rect.top){
                top = rect.top
              }
              if(bottom < rect.bottom){
                bottom = rect.bottom;
              }
            })
          }else{
            let rect = highlightEle.getBoundingClientRect();
            top = rect.top
            bottom = rect.bottom;
          }

          let distance = Math.min(Math.abs(top - screenMiddle),Math.abs(bottom - screenMiddle));
          if(min > distance){
            miniObj = alertObj;
            return distance;
          }else{
            return min;
          }
        },Infinity);
        if(miniObj){
          this.$parent.alertClick(miniObj);
        }else{
          this.$parent.alertClick(realReferenceObjs[isTop ? (realReferenceObjs.length - 1) :  0]);
        }
        this.showTopTip = this.showBottomTip = false;
      }else{
        this.$parent.alertsOffsetTop = this.currentPosition.top - this.$parent.$refs.scrollPanel.getBoundingClientRect().top;
      }
      this.showTopTip = this.showBottomTip = false;
    }
  },
  mounted() {
    window.setOffset = (n) => {
      this.$parent.alertsOffsetTop =  n;
    }

    if(!this.$refs.groupSelf) return;
    this.updateScrollRef();
    this.updateOffsetTop();
    this.updateGroupHeight();
    this.$nextTick(()=>{
      this.updateCurrentPosition();
      this.updateTipsPosition();
      this.judgeCheckerExceedThreshold();
    })
  }
}
</script>

<style scoped>
.checker-invisible-tip-group{
  position: absolute;
  width: 100%;
}

.checker-tip{
  pointer-events: none;
  visibility: hidden;
}
.checker-tip.show{
  pointer-events: initial;
  visibility: visible;
}
</style>
