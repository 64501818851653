
// 关键词扩展的方向
export const ExtensionDirection = {
    Forward: 0, // 向前扩展
    Backend: 1 // 向后扩展
}
export const AlertType = {
    OTHER: 0, // 其他错误
    INSERT_BEFORE: 1,
    INSERT_AFTER: 2,
    DELETE: 3,
    REPLACE: 4,
    NO_OPERATION: 16, // 无需操作
    WRAP: 17// 用符号将句子包起来
}
export const DisplayMode = {
    SIMPLE: 0,//简单模式，
    COMPLETE:1 // 完全模式
}
//划线类型
export const LineBoxType = {
    HORIZONTAL:0,//水平模式
    VERTICAL:1, // 垂直模式
    HORIZONTAL_SPLIT:2//水平分散模式
}
export const AlertShowType = {
    HORIZONTAL:0,//水平划线模式
    VERTICAL:1, // 垂直划线模式
    DOC:2,//显示全文级别的错误
    NONE:3,//不显示划线
}
//展开之后的显示类型
export const ExpandDisplayType = {
    DEFAULT:0,//默认模式
    MODEL1:1,//前置审批专有模式
    MODEL_BLOCK_LIST:2,//显示多行文本，有的可以被点击，有的不能被点击，有的点击了高亮全文，有的点击了高亮一段文字。
}
//alert扩展信息的类型。
export const AlertExtensionType = {
    ALERT_MSG_CLICK: "alertMsgClick",
    MAIN_RISK: "mainRisk",
    PRE_APPROVAL: "preApproval",
    SHORT_MSG: "shortMsg",
    CLAUSE_TYPE:"clauseType",
    CONFUSE_TERM:"confuseTerm",
    BLOCK_LIST:"blockList",
    REF_CLAUSE:"refClause",
    FOLD_ALERT:"foldAlert"
}
export const BlockMessageType = {
    HIT: "hit",
    MISS: "miss"
};
export const AlertMsgClickType = {
    OPEN_COMPANY_META_INFO:"openCompanyMetaInfo"
}
//检查mode
export const CheckMode = {
    START_CHECK: 0,
    CHECK_REST: 1,
    RE_CHECK:2
}
//业务忙code
export const BUSY_ERROR_CODE = 600
export const RESULT_CODE = {
    SUCCESS: 0,
    WAITING: 100,
    WAITING_NEW: 101,
    WAITING_FOR_CONFIRMATION: 102,
    WAITING_PURCHASE_PACKAGE: 103,
    WAITING_REFRESH_TO_LOAD: 104,
    ERROR: 500
}
export const SavingStatus={
    saving:1,
    saved: 0,
    failed: 2
}
export const ErrorType = {
    ALL: -1, // 所有
    OTHER: 0, // 其它
    TYPO: 1, // 错别字
    PUNCTUATION_MISUSE: 2, // 标点符号误用
    WORD_ORDER_ERROR: 3, // 语序错误
    SYNONYMOUS_CONFUSION: 4, // 同义词混淆
    GRAMMAR_ERROR: 5, // 语法错误
    ADVANCED_TIPS: 100,//只作为分界线 不可选，高级提示的所有错误类型都大于改值
    DATE_ERROR: 101,//日期错误
    AMOUNT_ERROR: 102,//金额错误
    BRAND_ERROR: 103,//专用名词错误
    DISUNITY_ERROR: 104,//重复定义
    SERIAL_ERROR: 105,//序标错误
    LAW_DEFINE: 106,
    ERROR_LINK: 107,
    CONTRACT_RISK:51, //合同风险
    NONSTANDARD_CLAUSE:52, //非标准条款
    CUSTOM_COMMENT:53, // 自定义批注
    STANDARD:54, // 规范错误
    FOLD_ALERT:56, // "折叠提示"
}
// 某一条提示信息的展示状态
export const AlertDisplayStatus = {
    COLLAPSE: 0, // 折叠状态
    EXPAND: 1 ,// 展开状态
}
export const ErrorTypeMenuItemKey = {
    ALL: ErrorType.ALL, // 所有
    TYPO: ErrorType.TYPO, // 错别字
    PUNCTUATION_MISUSE: ErrorType.PUNCTUATION_MISUSE, // 标点符号误用
    CONTRACT_RISK: ErrorType.CONTRACT_RISK, // 合同风险
    CUSTOM_COMMENT: ErrorType.CUSTOM_COMMENT, // 自定义批注
    STANDARD: ErrorType.STANDARD, // 规范错误
    NONSTANDARD_CLAUSE: ErrorType.NONSTANDARD_CLAUSE, // 非标准条款
    IGNORE: -100, // 用于存放忽略的错误数量
}
/**
 * 获取划线显示类型
 * @param showType
 * @return {number}
 */
export const getAlertShowType = function (showType) {
    if (AlertShowType.VERTICAL === showType) {
        return AlertShowType.VERTICAL
    }else if (AlertShowType.DOC === showType){
        return AlertShowType.DOC
    }else if (AlertShowType.NONE === showType) {
        return AlertShowType.NONE
    }
    return AlertShowType.HORIZONTAL;
};

// 显示高亮区域的mode
export const HighlightMode = {
    ALERT: 0,// 显示所有alert的highlight
    ALERT_BLOCK: 1,// 显示某个alert的某个子block的highlight
};
// 合同检查模式。
export const ContractCheckMode = {
    READ:0,
    EDIT:1
}
