<template>
    <div>
        <el-dialog v-model="contractSettingDialogVisible" title="审查设置" width="50%" center>
            <div v-if="isNotNUll(settingJSONObject['立场'])" class="flex">
                <div class="setting-item-key">立场：</div>
                <div class="setting-item-value">
                    <el-radio-group v-model="settingJSONObject['立场']['position']">
                        <el-radio v-for="item in settingJSONObject['立场']['alternatives']" :label="item">{{item}}</el-radio>
                        <el-radio label="不明">不明</el-radio>
                    </el-radio-group>
                </div>
            </div>
            <div v-if="isNotNUll(settingJSONObject['一二级角色关联'])" style="margin: 10px 0">
                <div class="setting-item-key">一二级角色：</div>
                <div style="margin: 10px 0;">
                    <div v-for="(roleValue, roleKey) in settingJSONObject['一二级角色关联']" class="flex">
                        <div class="setting-item-key">{{roleKey}}：</div>
                        <div class="setting-item-value">
                            <el-radio-group v-model="roleValue['一级角色名']">
                                <el-radio v-for="item in roleValue['选项']" :label="item">{{item}}</el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="isNotNUll(settingJSONObject['标的类型'])" class="flex">
                <div class="setting-item-key">标的类型：</div>
                <div class="setting-item-value">
                    <el-checkbox-group v-model="settingJSONObject['标的类型']">
                        <el-checkbox label="服务" />
                        <el-checkbox label="虚拟" />
                        <el-checkbox label="实体" />
                    </el-checkbox-group>
                </div>
            </div>
            <div v-if="isNotNUll(settingJSONObject['框架协议'])" class="flex">
                <div class="setting-item-key">框架协议：</div>
                <div class="setting-item-value">
                    <el-radio v-model="settingJSONObject['框架协议']" :label="true" size="large">是</el-radio>
                    <el-radio v-model="settingJSONObject['框架协议']" :label="false" size="large">否</el-radio>
                </div>
            </div>
            <div v-if="isNotNUll(settingJSONObject['合同模板'])" class="flex">
                <div class="setting-item-key">合同模板：</div>
                <div class="setting-item-value">
                    <el-radio v-model="settingJSONObject['合同模板']" :label="true" size="large">是</el-radio>
                    <el-radio v-model="settingJSONObject['合同模板']" :label="false" size="large">否</el-radio>
                </div>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="contractSettingDialogVisible = false">取消</el-button>
                <el-button type="primary" @click="contractSettingConfirm">确定</el-button>
              </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>

    import { h } from 'vue'
    import { ElMessage, ElMessageBox } from 'element-plus'

    export default {
        name: "ContractSetting",
        props: ['setting', 'autoOpenDialog'],
        components: {},
        data() {
            return {
                settingJSONObject: JSON.parse(this.setting),
                contractSettingDialogVisible: false,
            }
        },
        created() {

        },
        mounted() {
            this.init();
        },
        beforeUnmount() {
        },
        watch: {
        },
        methods: {
            init: function () {
                console.log('this.settingJSONObject === ', this.settingJSONObject);
                if (this.autoOpenDialog === true) {
                    this.contractSettingDialogVisible = true;
                } else {
                    if (this.settingJSONObject && this.settingJSONObject['立场'] && this.settingJSONObject['立场']['position'] === '不明') {
                        ElMessageBox({
                            title: '信息',
                            message: h('p', null, [
                                h('span', null, '检测到您还没有设置立场，是否需要现在设置立场？'),
                            ]),
                            showCancelButton: true,
                            confirmButtonText: '是',
                            cancelButtonText: '否',
                            beforeClose: (action, instance, done) => {
                                if (action === 'confirm') {
                                    done()
                                    this.contractSettingDialogVisible = true;
                                } else {
                                    done()
                                }
                            },
                        });
                    }
                }
            },
            contractSettingConfirm: function () {
                this.contractSettingDialogVisible = false;
                this.$emit('setting-changed', JSON.stringify(this.settingJSONObject));
            },
            isNotNUll: function (obj) {
                return obj != null && obj != undefined;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import url("../../assets/css/alert.css");


    .contract-checker-container {
        padding-top: 10px;
        min-height: 300px;
    }

    .flex {
        display: flex;
        line-height: 30px;
    }

    .setting-item-key {
        width: 100px;
        text-align: right;
    }

    .setting-item-value {
        margin-left: 20px;
    }

    .setting-item-value .el-radio {
        margin-right: 60px;
    }

    .setting-item-value .el-checkbox {
        margin-right: 60px;
    }

</style>
