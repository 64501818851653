<template>
  <div class="meta-comment">
      <slot name="default"></slot>
    <div class="comment-dialogs" ref="commentsRef">
      <slot name="comments"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Comment",
  data(){
    return {
      commentRefs:[]
    }
  },
  methods:{
    addCommentRef(ref){
      this.commentRefs.push(ref);
    },
    removeCommentRef(ref){
      this.commentRefs = this.commentRefs.filter(_ref => _ref !== ref);
    }
  }
}
</script>

<style scoped>

</style>
